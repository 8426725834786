.button-purchase {
  width: 90px;
  height: 34px;
  text-align: center;
  .list-calendar-information + & {
    margin-left: 10px;
  }
  > a,
  > span {
    display: block;
    width: 100%;
    height: 34px;
    line-height: 34px;
    position: relative;
    padding: 0 0 0 28px;
    box-sizing: border-box;
    font-size: 14px;
    border-radius: 3px;
    font-weight: 700;
    background: $c_button_default_bg;

    span {
      @include f_narrow;
      color: $c_text_primary;
      display: block;

    }
    &:before {
      content: "";
      position: absolute;
      left: 10px;
      top: 0;
      background-repeat: no-repeat;
      background-size: 14px auto;
      width: 14px;
      height: 100%;
      background-position: center;
    }
  }
  &.is-green1,
  &.is-green2,
  &.is-yellow1,
  &.is-yellow2 {
    > a,
    > span {
      background: $c_button_purchase_bg;
      span {
        color: $c_button_purchase_text;
      }
    }
  }
  &.is-green1 {
    > a,
    > span {
      &:before {
        background-image: url(../img/icon-status_green1.png);
      }
    }
  }
  &.is-green2 {
    > a,
    > span {
      &:before {
        background-image: url(../img/icon-status_green2.png);
      }
    }
  }
  &.is-yellow1 {
    > a,
    > span {
      &:before {
        background-image: url(../img/icon-status_yellow1.png);
      }

    }
  }
  &.is-yellow2 {
    > a,
    > span {
      &:before {
        background-image: url(../img/icon-status_yellow1.png);
      }
    }
  }
  // &.is-red {
  //   > a,
  //   > span {
  //     &:before {
  //       background-image: url(../img/icon-status_red.png);
  //     }
  //   }
  // }
  
  &.is-notyet {
    > a,
    > span {
      padding: 0;
      > span {
        width: 116%;
        opacity: 0.32;
      }
      &:before {
        display: none;
        // background-image: url(../img/icon-status_green2.png);
      }
    }
  }
  &.is-over {
    > a,
    > span {
      &:before {
        background-image: url(../img/icon-status_red.png);
      }
    }
  }



}