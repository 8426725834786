.wysiwyg-wrap {
  background: #fff;
  padding: 20px $padding_wrap;
  box-sizing: border-box;
}
.l-wysiwyg {
  font-size: 15px;
  color: rgba(0,0,0,.87);
  margin: 0 auto;
  max-width: 640px;
  margin: 0 auto;
  padding: 20px 0;
  line-height: 1.625;
  p, div, table, ul , ol {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  img {
    max-width: 100%;
    height: auto;
  }
  h2 {
    @include f_min_narrow;
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: bold;
  }
  h3 {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
  }
  blockquote {
    @include f_min_narrow;
    margin-bottom: 20px;
    font-size: 19px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    transform-origin: center;
  }
  .small,
  small {
    font-size: 12px;
  }
  .red {
    color: #ff1744;
  }
  .blue {
    color: #536a9d;
  }
  .pink {
    color: #ed418c;
  }
  .green {
    color: #3b8d51;
  }
  .gray {
    color: rgba(0,0,0,.6);
  }
  .bg-yellow {
    background: #ffff3e;
  }
  .bg-green {
    background: #c1ff7a;
  }
  b,
  strong {
    font-weight: bold;
  }
  i,
  em {
    font-style: italic;
  }
  s {
    text-decoration: line-through;
  }
  a {
    color: #007b7b;
    text-decoration: none;
    font-weight: bold;
  }
  .enclosure {
    border: 1px solid #e0e0e0;
    padding: 15px;
  }
  hr {
    border: none;
    height: 1px;
    background-color: #e0e0e0;
    padding: 0;
    margin: 15px 0;
  }
  .txt-r {
    text-align: right;
    transform-origin: right center;
  }
  .txt-c {
    text-align: center;
    transform-origin: center;
  }
  iframe {
    margin-bottom: 20px;
    max-width: 100%;
  }
  .youtube {
    width:100%;
    padding-bottom: 56.25%;
    height:0px;
    position: relative;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }    
  }

  ol {
    padding-left: 20px;
    li {
      list-style-type: decimal;
      padding-left: 5px;
    }
  }
  ul {
    padding-left: 25px;
    li {
      list-style-type: disc;
      padding-left: 0px;
    }
  }
  table {
    width: 100%;
    border-top: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    th, td {
      border-right: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
      padding: 10px;
      text-align: left;
    }
    th {
      background: #f2f2f2;
      font-weight: bold;
      padding-right: 20px;
    }
  }

}
