.section-heading {
  padding: 25px $padding_wrap;
  font-size: 18px;
  font-weight: 700;
  span {
    display: block;
    font-size: 12px;
    @include f_sg_bold;
    margin: 6px 0 0 0;
  }
  .l-archive & {
    &:first-child {
      margin-top: -20px;
    }
  }
}