.l-slider {
  width: 100%;
  border-bottom: 1px solid rgba(0,0,0,.12);
  .slider-inner {
    transition: opacity $t_duration $t_easing;
    opacity: 0;
    .slick-slide {
      position: relative;
      line-height: 0;
      background: #000;
      height: auto;
      margin: 0 2px;
      width: calc(100vw - 30px);
      @include mqTB {
        width: calc(87.5vw - 30px);
        max-width: $max-width - 30px;
      }
      > a,
      > div {
        display: block;
        height: 0px;
        padding-top: 56.25%;
      }
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 0;
        transform: translate3d(-50%,-50%,0);
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
      }
      .slider-toptext,
      .slider-bottomtext {
        position: absolute;
        width: 100%;
        z-index: 1;
        background: #000;
        left: 0;
        span {
          display: block;
          width: calc(100% - 20px);
          margin: 0 auto;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          height: 24px;
          line-height: 24px;
          @include mqTB {
            height: 30px;
            line-height: 30px;
          }
        }
      }
      .slider-toptext {
        top: 0;
        font-size: 11px;
        color: rgba(255,255,255,.6);
        @include mqTB {
          font-size: 12px;
        }
      }
      .slider-bottomtext {
        bottom: 0;
        font-size: 12px;
        color: #fff;
        @include mqTB {
          font-size: 15px;
        }
      }
    }
    a {
      line-height: 0;
      padding: 0 2.5px;
      display: block;
      outline: none;
    }
  }
  .slider-inner.slick-initialized {
    opacity: 1;
  }
}
.slick-dotted.slick-slider {
  margin-bottom: 36px;
}
.slick-dots {
  bottom: -21px;
  li {
    width: 6px;
    height: 6px;
    margin: 0 5px;
    button {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      border: 1px solid $c_text_primary;
      padding: 0;
      &:before {
        display: none;
      }
    }
    &.slick-active {
      button {
        background: $c_text_primary;
      }
    }
  }
}