//
// fonts
//

// Noto Sans JP Regular / Noto Sans JP Bold
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700&subset=japanese');

// Noto Serif JP Bold
@import url('https://fonts.googleapis.com/css?family=Noto+Serif+JP:700&subset=japanese');

// YakuHanJP：ゴシック体（約物全部入り）
@import url('https://cdn.jsdelivr.net/npm/yakuhanjp@3.0.0/dist/css/yakuhanjp.min.css');

// Space Grotesk Bold
@font-face {
  font-family: "SpaceGrotesk";
  font-style: normal;
  font-weight: 700;
  src: local('SpaceGrotesk-Bold'), 
    url('fonts/SpaceGrotesk-v1.1.0/SpaceGrotesk-Bold.woff2') format('woff2'), 
    url('fonts/SpaceGrotesk-v1.1.0/SpaceGrotesk-Bold.woff') format('woff')
}

$f_default: YakuHanJP, 'Noto Sans JP', "游ゴシック体", 'Yu Gothic', YuGothic, "ヒラギノ角ゴ Pro", 'Hiragino Kaku Gothic Pro', Meiryo, sans-serif;
$f_min: YakuHanJP, 'Noto Serif JP', "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
$f_sg: 'SpaceGrotesk', sans-serif;

@mixin f_default {
  font-family: $f_default;
}

// 長体
@mixin f_narrow {
  font-family: $f_default;
  transform: scaleX(.88); transform-origin: left center;
}

// 長体 + 明朝
@mixin f_min_narrow {
  font-family: $f_min;
  transform: scaleX(.88); transform-origin: left center;
}

// Space Grotesk Bold
@mixin f_sg_bold {
  font-family: $f_sg;
  font-weight: 700;
  letter-spacing: .025em;
}