.page-heading {
  font-size: 20px !important;
  color: rgba(0,0,0,.87);
  border-top: 4px solid #e0e0e0;
  padding: 15px 0 0 0;
  margin: 0 0 20px 0;
}
.page-heading_sub {
  font-size: 18px !important;
  color: rgba(0,0,0,.87);
  border-top: 1px solid #e0e0e0;
  padding: 15px 0 0 0;
  margin: 0 0 20px 0;
}
.page-link {
  text-align: right;
  background: #f2f2f2;
  padding: 10px;
  a {
    position: relative;
    padding-right: 23px;
    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 13px;
      height: 100%;
      background-image: url(../img/arrow-link.png);
      background-size: 13px 13px;
      background-repeat: no-repeat;
      background-position: right center;
    }
  }
  .archive-description & {
    margin-top: 10px;
  }
}
.page-heading_decorated {
  font-size: 20px !important;
  font-weight: bold;
  border-top: 4px solid #e0e0e0;
  padding: 15px 0 0 0;
  margin: 0 0 20px 0;
  > div {
    display: flex;
    align-items: baseline;
    span:nth-child(1) {
      color: rgba(0,0,0,.6);
      position: relative;
      top: -4px;
      @include f_sg_bold;
      font-size: 15px;
      padding: 0 15px 0 0;
    }
  }
  .page-heading_decorated-sub {
    font-weight: normal;
    font-size: 14px;
  }

}

